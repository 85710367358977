import React, { Component } from "react"
import { graphql } from "gatsby"
import { sortReviewsByDate } from "../utils/utils"

import Layout from "../components/layout"
import SEO from "../components/seo"
import StaticHeading from "./index/StaticHeading"
import Sizzle from "./index/Sizzle"
import AllRatingsReviews from "../components/SocialReviews/AllSocialReviews"
import FirstTime from "./index/FirstTime"
import InTheCommunity from "./index/InTheCommunity"
import LearnMoreCards from "./index/LearnMoreCards"
import TheaterVideo from "@components/Theater/TheaterVideo"
import StaticHero from "./index/StaticHero"
import { TestimonialPreview } from "../components/Cards"
import { Image, Text } from "../components/Core"
import { Columns, Section } from "../components/Containers"
import TestimonialGrid from "../components/Cards/TestimonialGrid"
import { ProgramToast } from "../components/program-toast"
import { Button } from "../components/Button"

class IndexPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      playing: false
    }
  }

  render() {
    const post = this.props.data.allUniquePagesJson.nodes[0]
    const { metaTitle, metaDescription, language } = this.props.pageContext

    const staticStyle = {
      position: "relative",
      overflow: "unset",
      width: "100%",
      zIndex: 1
    }
    const sizzleButtonStyle = {
      position: "absolute",
      top: 0,
      zIndex: 200
    }

    const hasReviews = this.props.data.hasReviews.nodes[0].reviews
    let allReviews = this.props.data.allReviews.nodes

    const sortedReviews = sortReviewsByDate(allReviews)

    // shuffleArray(allReviews);

    let fixedFacewallReviews = []
    if (sortedReviews.length >= 8) {
      fixedFacewallReviews = sortedReviews.slice(0, 8)
    } else {
      fixedFacewallReviews = sortedReviews.slice(0, 4)
    }

    let schemaData = null
    if (language === "en") {
      schemaData = {
        presets: post.schemas.presets,
        customSchema: post.schemas.custom,
        dateModified: this.props.pageContext.dateModified,
        language,
        metaTitle: metaTitle,
        description: metaDescription,
        path: this.props.location.pathname
      }
    }

    return (
      <Layout
        navSpacer
        className={`main-homepage ${language}`}
        language={language}
        layoutClass="homepage-fab"
        noFab
        pageTitle="main-homepage">
        <SEO
          title={metaTitle}
          description={metaDescription}
          schemaData={schemaData}
          pathname={this.props.location.pathname}
          lang={language}
        />

        <div className="static-container" style={staticStyle}>
          {post.hasSizzle ? (
            <Sizzle
              playing={this.state.playing}
              sizzleButtonStyle={sizzleButtonStyle}
              youtube={post.sizzleYoutube}
              post={post}
              language={language}>
              <StaticHeading
                playing={this.state.playing}
                youtube={post.sizzleYoutube}
                heading={post.hero.heading}
                subheading={post.hero.subheading}
                language={language}
              />
            </Sizzle>
          ) : (
            <StaticHero
              post={post}
              heading={post.hero.heading}
              subheading={post.hero.subheading}
              bgImage={post.hero.bgImage}
              language={language}
            />
          )}

          {language == "en" && hasReviews && (
            <Section
              colorBack
              zeroTop
              zeroBottom
              className="homepage__testimonial-section">
              <Columns
                sideColumnsSize={0}
                className="homepage__testimonial-wall">
                <div className="column">
                  <Text
                    as="h4"
                    className="has-text-centered mb-2 mt-2--mobile"
                    text="Hear Stories From People Just Like You"
                  />
                  {post.featuredReviews.length > 1 && (
                    <div className="columns">
                      {post.featuredReviews.map(card => (
                        <div key={card.youtube} className="column">
                          <TestimonialPreview
                            language={language}
                            youtube={card.youtube}
                            image={card.image}
                            heading={card.heading}
                            subheading={card.subHeading}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                  <TestimonialGrid language={language} reviews={allReviews} />
                </div>
              </Columns>
            </Section>
          )}

          <LearnMoreCards language={language} post={post.learnMoreCards} />

          {/* <ImageText
            language={language}
            post={post}
            textImage={post.homeSectionOne.imageId}
            noReverse
            colorBack={language === "es"}
          /> */}

          <Section colorBack>
            <Columns sideColumnsSize={2}>
              <div className="column main-homepage__about-section">
                <Text
                  className="has-text-centered px-40--mobile"
                  as="h2"
                  text={post.homeSectionOne.heading}
                />
                <Text
                  useStringReplace
                  className="px-40--mobile"
                  as="p"
                  text={post.homeSectionOne.text}
                />
              </div>
            </Columns>
          </Section>

          <Section>
            <Columns sideColumnsSize={2}>
              <div className="column main-homepage__about-section">
                <Text
                  className="has-text-centered px-40--mobile"
                  as="h2"
                  text="<a class='color-blue-dark' href='/procedure/dental-implants-near-south-bend-in/' title='Dental implants near South Bend, ID' >Dental Implants</a> & <a class='color-blue-dark' href='/procedure/wisdom-teeth-removal-near-south-bend-in/' title='Wisdom teeth removal near South Bend, ID' >Wisdom Teeth Removal</a> in Granger & South Bend, IN"
                />
                <Text
                  useStringReplace
                  className=" px-40--mobile"
                  as="p"
                  text={`Patient satisfaction is our number one priority here at Granger Oral Surgery & Dental Implants near South Bend, IN. We understand the uncertainty and stress that surgical procedures may cause, which is why we work so hard to make our patients feel comfortable moving forward with treatment. We will give you the individualized attention you deserve through the entire process — from your initial evaluation to your recovery. Please feel free to contact our office with any questions or concerns.`}
                />
              </div>
            </Columns>
          </Section>

          {post.award.hasThisSection && (
            <Section smallSpacing>
              <Columns>
                <div className="column main-homepage__award-section">
                  <a
                    href={post.award.url}
                    title="View our 2024 best dental clinic award"
                    target="_blank">
                    <div className="is-hidden-touch">
                      <Image publicId={post.award.image} />
                    </div>
                    <div className="is-hidden-desktop">
                      <Image useAR={false} publicId={post.award.mobileImage} />
                    </div>
                  </a>
                </div>
              </Columns>
            </Section>
          )}

          {post.book.hasThisSection && (
            <Section>
              <Columns sideColumnsSize={2}>
                <div className="column main-homepage__mts-section">
                  <div className="mts-content">
                    <Text as="h2" text={post.book.heading} />
                    <Text as="p" text={post.book.text} />
                    <div className="mts-button-group">
                      <TheaterVideo
                        videoUrl={`https://www.youtube.com/watch?v=${post.book.buttons[0].button.youtube}`}
                        controls
                        playing
                        onlyButton
                        buttonClass="contained"
                        compId="sizzleTheaterButton"
                        language={language}
                      />
                      {/* {post.book.buttons.map(({ button }, idx) => (
                        <Button
                          key={idx}
                          appearance={button.appearance}
                          destination={button.destination}
                          buttonText={button.buttonText}
                          href={button.href}
                          youtube={button.youtube}
                        />
                      ))} */}
                    </div>
                  </div>
                  <div className="mts-image-section">
                    <Image
                      publicId={post.book.image}
                      wrapperClassName="mts-img"
                    />
                  </div>
                </div>
              </Columns>
            </Section>
          )}

          {/* <Section>
            <Columns sideColumnsSize={2}>
              <div className="column px-40--mobile main-homepage__recovery-section">
                <div className="recovery-section-img-section">
                  <Image
                    publicId="GROS/DEV/gros-revolutionizing-recovery"
                    wrapperClassName="recovery-section-img"
                  />
                </div>
                <div className="recovery-section-content">
                  <Text as="h2" text={"Revolutionizing Recovery"} />
                  <Text
                    as="p"
                    text="Our innovative photobiomodulation and platelet-rich fiber therapies reduce pain, decrease swelling, and promote post-operative healing faster than traditional methods."
                  />
                  <TheaterVideo
                    // videoUrl={`https://www.youtube.com/watch?v=${youtube}`}
                    controls
                    playing
                    hasWatchVideo
                    buttonClass="contained"
                  />
                </div>
              </div>
            </Columns>
          </Section> */}

          {post.inTheCommunity.hasThisSection && (
            <Section colorBack>
              <InTheCommunity
                heading={post.inTheCommunity.heading}
                blurb={post.inTheCommunity.text}
                buttons={post.inTheCommunity.buttons}
                image={post.inTheCommunity.image}
              />
            </Section>
          )}
          {/* <HeadingVideo post={post} /> */}

          <FirstTime
            language={language}
            post={post}
            useImages={post.firstTimePatient.useImages}
            image={post.firstTimePatient.image}
            noReverse
          />

          {post.homeRatingsReviews.hasThisSection && (
            <Section zeroTop zeroBottom>
              <AllRatingsReviews
                animation
                heading={post.homeRatingsReviews.heading}
                blurb={post.homeRatingsReviews.blurb}
                language={language}
                buttonHref={post.homeRatingsReviews.buttonOne.href}
                buttonText={post.homeRatingsReviews.buttonOne.buttonText}
              />
            </Section>
          )}
        </div>
        {post.toast.hasThisSection && (
          <ProgramToast closeDate={post.toast.closeDate}>
            {post.toast.text}
          </ProgramToast>
        )}
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query reviewsIndexQuery($title: String!, $language: String!) {
    allReviews: allReviewsJson(
      filter: { reviewType: { eq: "Patient" }, language: { eq: $language } }
      limit: 30
    ) {
      nodes {
        ...FacewallData
      }
    }
    hasReviews: allDataJson(filter: { optionsItem: { eq: "build-pages" } }) {
      nodes {
        reviews
      }
    }
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        schemas {
          presets
          custom
        }
        hasSizzle
        hero {
          heading
          subheading
          bgImage
        }
        toast {
          hasThisSection
          text
          closeDate
        }
        featuredReviews {
          heading
          subHeading
          youtube
          image
        }
        homeSectionOne {
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          heading
          imageId
          text
        }
        learnMoreCards {
          blurb
          hasThisSection
          heading
          cards {
            blurb
            heading
            imageId
            button {
              buttonText
              href
            }
          }
        }
        homeRatingsReviews {
          hasThisSection
          heading
          # blurb
          buttonOne {
            buttonText
            href
          }
        }
        award {
          hasThisSection
          url
          image
          mobileImage
        }
        book {
          hasThisSection
          heading
          text
          image
          buttons {
            button {
              buttonText
              href
              destination
              appearance
              youtube
            }
          }
        }
        inTheCommunity {
          buttons {
            button {
              buttonText
              href
              appearance
              destination
            }
          }
          hasThisSection
          heading
          imageId
          leftRight
          subheading
          text
        }
        firstTimePatient {
          buttons {
            button {
              href
              buttonText
              destination
              appearance
            }
          }
          heading
          text
          useImages
          image
        }
        metaTitle
        sizzleYoutube
        metaDescription
        title
      }
    }
  }
`

export default IndexPage
